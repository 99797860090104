import React from 'react';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import {
  RamblerRecommendationBlock,
  RecommendationBlockItemType,
} from 'common/components/RamblerComponents/RamblerRecommendationBlock';

import { useRecommendedNewsWidget } from '../TopNewsWidget/hooks/useRecommendedNewsWidget';

type GetItemsPropsType = {
  clusters: CardData[];
  onClick: RecommendationBlockItemType['onClick'];
};

/**
 * Функция получения массива item'ов из карточек.
 * @param props.clusters - массив карточек кластеров;
 * @param props.onClick - функция клика по новости, используется для отправки в ЯМ.
 */
export const getItems = ({ clusters, onClick }: GetItemsPropsType) =>
  clusters.map(
    (cluster) =>
      ({
        ...cluster.item,
        comments_count: cluster.commentsCount,
        onClick,
      }) as RecommendationBlockItemType,
  );

type RecommendationBlockPropsType = {
  clusterId: CardData['id'];
  level: number;
  title?: string;
  withImage?: boolean;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
  onClick?: () => void;
};

/**
 * Виджет рекоммендуемых новостей.
 * @param props.clusterId - id кластера;
 * @param props.title - заголовок виджета;
 * @param props.withImage - флаг, что нужна картинка для первой новости;
 * @param props.onClick - функция клика по новости, используется для отправки в ЯМ;
 * @param props.containerRef - реф для контейнера виджета, чтобы получать высоту выше.
 */
export const RecommendationBlock = withErrorBoundary(
  function RecommendationBlock({
    clusterId,
    title = 'Рекомендуем',
    withImage = false,
    containerRef,
    onClick,
  }: RecommendationBlockPropsType) {
    const { clusters } = useRecommendedNewsWidget(clusterId);

    const items = getItems({ clusters, onClick });

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <div ref={containerRef} onClick={onClick}>
        <RamblerRecommendationBlock
          title={title}
          projectType="media"
          items={items}
          withImage={withImage}
        />
      </div>
    );
  },
);
