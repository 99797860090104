import { INewsCard } from '@media-components/news-card';
import React from 'react';

import { Card } from 'common/components/Card';
import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { ReachGoalFirstCardWrapper } from 'common/components/metrics/ReachGoalFirstCardWrapper';

import s from './styles.module.css';

type MainCardPropsType = {
  clusterId: CardData['id'];
  currentTime: INewsCard['currentTime'];
};

/**
 * Компонент первой карточки
 * @param clusterId – id кластера;
 * @param currentTime – текущее время;
 */
export const MainCard = withErrorBoundary(function MainCard({
  clusterId,
  currentTime,
}: MainCardPropsType) {
  return (
    <ReachGoalFirstCardWrapper key={clusterId} styles={s.firstCard}>
      <Card
        index={1}
        className={s.big}
        currentTime={currentTime}
        type="top-first-desktop"
        clusterId={clusterId}
      />
      {/*
       * Нужно для отрисовки карточки, что может иметь совсем разные размеры
       *  на широкой и узкой версии.
       * В итоге в одно время у нас рисуется только одна карточка,
       *  причем она не зависит от css.
       */}
      <Card
        index={1}
        className={s.small}
        currentTime={currentTime}
        type="top-secondary-desktop"
        clusterId={clusterId}
      />
    </ReachGoalFirstCardWrapper>
  );
});
