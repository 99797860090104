import _chunk from 'lodash.chunk';
import React, { useMemo } from 'react';

import { Ad } from 'common/components/Ad';
import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { RamblerWidgetsCarousel } from 'common/components/RamblerComponents/RamblerWidgetsCarousel';
import { RamblerCurrencyConverter } from 'common/components/RamblerEmbeds/RamblerCurrencyConverter';
import { RamblerWeather } from 'common/components/RamblerEmbeds/RamblerWeather';
import { LIMIT_BY_GIGA_FETCHING } from 'common/redux/pages/constants';
import { selectIsGigaChatShow } from 'common/redux/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { useAppSelector } from 'store/hooks';
import { getGlobalIndex } from 'utils/getGlobalIndex';

import { RecommendationBlock } from '../RecommendationBlock';

import { FirstLevel } from './components/FirstLevel';
import { SecondLevel } from './components/SecondLevel';
import { CHUNK_LAST, INJECTORS, SLICE_CONFIG } from './constants';

import s from './styles.module.css';

import 'desktop/css/banner--native-context_list_relaunch.css';
import 'desktop/css/banner--native-context_widget_relaunch.css';

type BaseRedesignPropsType = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
};

const CALENDAR_BUTTONS = [{ label: 'Сегодня', onClick: () => {} }];

/**
 * Базовый костяк для всей домашней страницы Новостей.
 * @param props.clusterIds - массив id кластеров;
 * @param props.puids - файл с настройками рекламы.
 */
export const BaseRedesign = withErrorBoundary(function BaseRedesign({
  clusterIds,
  puids,
}: BaseRedesignPropsType) {
  const gigaEnabled = useAppSelector(selectIsGigaChatShow);

  const slicedClusters = useMemo(() => {
    const copyClusterIds = [...clusterIds];

    if (gigaEnabled) {
      copyClusterIds.splice(0, LIMIT_BY_GIGA_FETCHING);
    }

    const sliced = SLICE_CONFIG.map((value) =>
      copyClusterIds.length > 0 ? copyClusterIds.splice(0, value) : undefined,
    ).filter((val) => !!val) as CardData['id'][][];

    if (copyClusterIds.length <= 0) {
      return sliced;
    }

    return [...sliced, ..._chunk(copyClusterIds, CHUNK_LAST)];
  }, [gigaEnabled, clusterIds]);

  return (
    <div className={s.root}>
      <FirstLevel clusterIds={slicedClusters[0]} puids={puids} />
      <div className={s.bottomBlock}>
        <RamblerWidgetsCarousel
          title="Календарь"
          extraControls={CALENDAR_BUTTONS}
          countToScroll={1}
        >
          {Array.from(new Array(10)).map((_item, index) => (
            <div key={index} className={s.calendarWidget} />
          ))}
        </RamblerWidgetsCarousel>
        <Ad name={Banners['100x70']} puids={puids} withoutMarginBottom />
      </div>
      {slicedClusters.slice(1).map((clusterIds, index) => (
        <SecondLevel
          key={index}
          clusterIds={clusterIds}
          injectElements={
            index >= INJECTORS.length
              ? INJECTORS[INJECTORS.length - 1]
              : INJECTORS[index]
          }
          puids={puids}
          bottomAdName={Banners.Superfooter}
          widgets={
            index === 0 ? (
              <>
                <RamblerCurrencyConverter />
                <RamblerWeather />
              </>
            ) : (
              <RecommendationBlock
                clusterId={clusterIds[0]}
                level={index + 2}
              />
            )
          }
          level={index + 2}
          indexOffset={getGlobalIndex(slicedClusters, index + 1) + 1}
        />
      ))}
    </div>
  );
});
