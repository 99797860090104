import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { Card } from 'common/components/Card';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import { selectCurrentServerTime } from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType, defaultCallback } from 'config/constants/common';
import { useAppSelector } from 'store/hooks';

import s from './styles.module.css';

type InjectorType = (index: number, puids: PuidsType) => React.ReactNode;

type SecondLevelPropsType = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
  bottomAdName: Banners;
  level: number;
  widgets?: React.ReactNode;
  indexOffset?: number;
  injectElements?: InjectorType;
};

/**
 * Компонент для отрисовки новостей начиная со второго экрана для вертикали Новости.
 * @param clusterIds - список идентификаторов кластеров;
 * @param puids - объект настроек для рекламы;
 * @param bottomAdName - название баннера в конце блока;
 * @param widgets - компонент с компонентами виджетов для боковой колонки;
 * @param injectElements - функция для внедрения компонентов внутрь списка новостей;
 * @param level - этаж на котором отображается компонент для отправки в ЯМ.
 */
export const SecondLevel = memo(function SecondLevel({
  clusterIds,
  puids,
  bottomAdName,
  widgets,
  indexOffset = 0,
  level,
  injectElements = defaultCallback as InjectorType,
}: SecondLevelPropsType) {
  const currentTime = useAppSelector(selectCurrentServerTime);

  return (
    <ReachGoalLevelWrapper level={level} styles={s.root}>
      <div className={s.content}>
        <div className={s.newsBlock}>
          {clusterIds.map((clusterId, index) => {
            const element = injectElements(index, puids);
            const card = (
              <Card
                key={clusterId}
                currentTime={currentTime}
                type="base-desktop"
                clusterId={clusterId}
                index={index + indexOffset}
                isLazy
              />
            );

            if (element) {
              return [card, element];
            }

            return card;
          })}
        </div>
      </div>
      <div className={s.widgets}>{widgets}</div>
      <div className={s.sideAd}>
        <Ad
          puids={puids}
          name={Banners['240x400']}
          withoutMarginBottom
          isLazy={false}
          isSticky
        />
      </div>
      <div className={s.bottomAd}>
        <Ad name={bottomAdName} puids={puids} withoutMarginBottom />
      </div>
    </ReachGoalLevelWrapper>
  );
});
