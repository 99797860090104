import React, { memo, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { CardContextWrapper } from 'common/components/CardContextWrapper';
import {
  RCM_BLOCK_TYPE,
  useBlockInitAtd,
  usePageViewAtd,
} from 'common/hooks/useRcm';
import { HomeHeader } from 'common/pages/headers/HomeHeader';
import { fetchFullRecsData } from 'common/redux/pages/home/asyncs';
import {
  selectHomeExcludedClustersIds,
  selectHomePageClusterIds,
  selectHomePageHasNextPage,
  selectHomePagePuids,
  selectHomePageRecommendClusterIds,
} from 'common/redux/pages/home/selectors';
import {
  selectIsHumanCenteredStrategyStateActivated,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { NATIVE_PUIDS, modifyPuids } from 'config/constants/common';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { Base } from 'desktop/components/Base';
import { BaseRedesign } from 'desktop/components/BaseRedesign';
import { BaseRedesignOneColumn } from 'desktop/components/BaseRedesignOneColumn';
import { Trigger } from 'desktop/components/Trigger';
import { useSliceClusters } from 'desktop/hooks/useSliceClusters';

import s from '../../css/page.css';

import { HomeRecommends } from './HomeRecommends';

/** Компонент домашней страницы */
const Home = memo(() => {
  const homeClusterIds = useSelector(selectHomePageClusterIds, shallowEqual);
  const homeRecommendClustersIds = useSelector(
    selectHomePageRecommendClusterIds,
    shallowEqual,
  );
  const homeExcludedClustersIds = useSelector(
    selectHomeExcludedClustersIds,
    shallowEqual,
  );
  const homeRawPuids = useSelector(selectHomePagePuids, shallowEqual);
  const homeHasNextPage = useSelector(selectHomePageHasNextPage);
  const forceRedesign = useSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );
  const projectId = useSelector(selectProjectId);

  const homePuids = useMemo(
    () => modifyPuids(homeRawPuids, NATIVE_PUIDS),
    [homeRawPuids],
  );

  const clusterIds = useMemo(
    () => [...homeClusterIds, ...homeRecommendClustersIds],
    [homeClusterIds, homeRecommendClustersIds],
  );

  const { slicedClusters } = useSliceClusters([
    ...homeClusterIds,
    ...homeRecommendClustersIds,
  ]);

  usePageViewAtd();
  useBlockInitAtd(RCM_BLOCK_TYPE.homeDesktop);

  const RedesignBaseComponent =
    projectId === PROJECT_IDS.News ? BaseRedesign : BaseRedesignOneColumn;

  usePageViewAtd();
  useBlockInitAtd(RCM_BLOCK_TYPE.homeDesktop);

  return (
    <CardContextWrapper
      selectClustersIds={selectHomePageClusterIds}
      rcmBlockType={RCM_BLOCK_TYPE.homeDesktop}
    >
      <div className={s.root}>
        <HomeHeader />
        {forceRedesign ? (
          <RedesignBaseComponent puids={homePuids} clusterIds={clusterIds} />
        ) : (
          <Base
            clusters={slicedClusters}
            puids={homePuids}
            excludedIds={homeClusterIds}
          />
        )}
        <Trigger
          ComponentRecommends={HomeRecommends}
          fetchRecsData={fetchFullRecsData}
          excludedRecsIds={homeExcludedClustersIds}
          hasNextPage={homeHasNextPage}
        />
      </div>
    </CardContextWrapper>
  );
});

export default Home;
