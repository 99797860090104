import cn from 'classnames';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { shallowEqual } from 'react-redux';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { useObserver } from 'common/hooks/useObserver';
import { selectRuntime } from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';
import { yaReachGoal } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

type ReachGoalGigaChatWrapperPropsType = {
  children: ReactNode;
  styles?: StylesType | string;
};

const OBSERVER_CONFIG = { threshold: 0.2 };
const GIGACHAT_IN_VIEW_TIMER = 18 * 1000; // 18 сек

/**
 * Компонент-обертка для отправки целей в ЯМ для гигачата
 * @param styles – стили блока.
 */
export const ReachGoalGigaChatWrapper = withErrorBoundary(
  function ReachGoalGigaChatWrapper({
    children,
    styles,
  }: ReachGoalGigaChatWrapperPropsType) {
    const runtime = useAppSelector(selectRuntime, shallowEqual);
    const [wasView, setWasView] = useState(false);
    const [isIntersecting, setIsIntersecting] = useState(false);

    const timerIdRef = useRef<NodeJS.Timeout>();

    // отправляем gigachat_read только в случае если он был во вьюпорте 18 сек
    const setSendReachGoalTimeout = useCallback(() => {
      timerIdRef.current = setTimeout(() => {
        if (!document.hidden && isIntersecting) {
          yaReachGoal(runtime, COUNTER_ID.WebMobile, 'gigachat_read');
        }
      }, GIGACHAT_IN_VIEW_TIMER);
    }, [isIntersecting, runtime]);

    useEffect(() => {
      setSendReachGoalTimeout();

      const callback = () => {
        if (document.hidden) {
          clearTimeout(timerIdRef.current);
        } else {
          setSendReachGoalTimeout();
        }
      };

      document.addEventListener('visibilitychange', callback);

      return () => {
        document.removeEventListener('visibilitychange', callback);
      };
    }, [setSendReachGoalTimeout]);

    const gigaChatViewCallback = useCallback(
      (entry: IntersectionObserverEntry) => {
        setIsIntersecting(entry.isIntersecting);

        if (!entry.isIntersecting) {
          clearTimeout(timerIdRef.current);
        }

        yaReachGoal(runtime, COUNTER_ID.WebMobile, 'gigachat_show');
        setWasView(true);
      },
      [runtime],
    );

    const gigaChatRef = useObserver<HTMLDivElement>({
      callback: wasView ? null : gigaChatViewCallback,
      observerConfig: OBSERVER_CONFIG,
    });

    const handleReachGoal = useCallback(() => {
      yaReachGoal(runtime, COUNTER_ID.WebMobile, 'gigachat_link_click');
    }, [runtime]);

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div ref={gigaChatRef} onClick={handleReachGoal} className={cn(styles)}>
        {children}
      </div>
    );
  },
);
